import type { DateTimeString } from "@/models/interfaces";

export interface StorageTempFileSchema {
  id: number;
  createdAt: DateTimeString;
  name: string;
  nameOriginal: string | null;
  contentType: string | null;
  size: number;
}

export const storageTempFileSchemaAllFields = [
  "id",
  "createdAt",
  "name",
  "nameOriginal",
  "contentType",
  "size"
];
import type { DateTimeString } from "@/models/interfaces";

export interface UserSchema {
  id: number,
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  firstName?: string;
  lastName?: string;
  userName: string;
  email: string;
  phoneNumber?: string;
  timezone: number;
  autoLogHours: boolean;

  active: boolean;
  saveFilters: boolean;

  roles: string[];

  rfidTag: number | null;
  doctorPassport: string | undefined;

  fullName?: string;
  userImage?: string;
}

export const userSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  firstName
  lastName
  userName
  email
  phoneNumber
  timezone
  autoLogHours

  active
  saveFilters

  roles

  rfidTag
  doctorPassport

  fullName
  userImage
`;

import GraphqlService from "../GraphqlService";
import type { DocumentTemplateSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { SaveDocumentTemplateInput } from "@/models/api/mutations/DocumentTemplateModels";

const fields = `
  id
  title
  text
  active
`;

export default class DocumentTemplateService {
  //#region Query
  async getDocumentTemplates(params: TabulatorParams) {
    return await GraphqlService.getItems<DocumentTemplateSchema>("documentTemplates", fields, params);
  }

  async getDocumentTemplate(id: number) {
    return await GraphqlService.getItem<DocumentTemplateSchema>("documentTemplate", fields, id);
  }
  //#endregion

  //#region Mutation
  async setActiveValueForDocumentTemplate(id: number, active: boolean) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setActiveValueForDocumentTemplate",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "active", value: active, valueType: "Boolean!" }
      ]
    });
    return data ?? false;
  }

  async deleteDocumentTemplate(id: number) {
    return await GraphqlService.deleteItem("deleteDocumentTemplate", id);
  }

  async saveDocumentTemplate(input: SaveDocumentTemplateInput) {
    return await GraphqlService.setItem<DocumentTemplateSchema>("saveDocumentTemplate", "documentTemplate", fields, input);
  }
  //#endregion
}

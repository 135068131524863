export default {
  head: {
    title: "הכנסות",
    meta: [
      { name: "description", content: "הכנסות" }
    ]
  },
  title: "הכנסות",
  draft: "טיוטה",
  filter: {
    search: "חיפוש",
    tooltip: "חפש לפי שם מטופל, כותרת, מספר מסמך",
    documentType: "סוג מסמך",
    incomeStatus: "סטטוס מסמך",
    paymentType: "סוג תשלום",
    amountFrom: "מסכום",
    amountTo: "עד סכום",
    date: "תאריך"
  },
  buttons: {
    approve: "אישור",
    preview: "צפיה",
    download: "הורדת מסמך",
    send: "שליחת מסמך",
    addRelated: "הפקת מסמך חדש מתוך מסמך זה",
    copy: "שכפול",
    view: "צפיה",
    delete: "מחיקת טיוטה",
    edit: "עריכת טיוטה"
  },
  dropdown: {
    add: "הוספת מסמך",
    estimate: "הצעת מחיר",
    proformaInvoice: "חשבון עסקה",
    invoice: "חשבונית מס",
    invoiceReceipt: "חשבונית מס קבלה",
    receipt: "קבלה",
    invoiceRefound: "חשבונית זיכוי",
    receiptRefound: "קבלה זיכוי"
  },
  table: {
    columns: {
      financialNumber: "מספר",
      documentDate: "מועד המסמך",
      incomeType: "סוג מסמך",
      patientName: "שם מטופל",
      description: "תיאור",
      payAmount: "סכום"
    }
  }
}

<template>
  <div class="p-formkit">
    <VueDatepicker ref="datePicker" v-model="context._value" v-bind="context.attrs" @input="handleInput" :class="styleClass" />
  </div>
</template>

<!-- https://github.com/sfxcode/formkit-primevue/blob/main/src/components/PrimeCalendar.vue -->

<script setup lang="ts">
  import VueDatepicker from "../other/VueDatepicker.vue";
  import { type PropType, ref, computed, onMounted } from "vue";
  import type { FormKitFrameworkContext } from "@formkit/core";

  const props = defineProps({
    context: {
      type: Object as PropType<FormKitFrameworkContext>,
      required: true
    }
  });

  const closeDefaultValue = true;
  const close = computed(() => props.context.attrs.closeOnSelect ?? closeDefaultValue);

  const datePicker = ref<InstanceType<typeof VueDatepicker>>();

  function handleInput(_: any) {
    props.context.node.input(props.context._value);

    if (close.value && datePicker.value) {
      datePicker.value.datePicker?.closeMenu();
    }
  }

  const styleClass = computed(() =>
    props.context.state.validationVisible && !props.context.state.valid ? `${props.context.attrs?.class} p-invalid` : props.context.attrs?.class
  );

  function show() {
    datePicker.value?.datePicker?.openMenu();
  }

  onMounted(() => {
    props.context.show = show;
  });
</script>

export default {
  head: {
    title: "Calendar",
    meta: [
      { name: "description", content: "Calendar" }
    ]
  },
  day: {
    title: "Day"
  },
  week: {
    title: "Week"
  },
  list: {
    title: "List"
  },
  listShort: {
    title: "List short"
  },
  popups: {
    printCalendar: {
      title: "Journal printing",
      dateFrom: "From date",
      dateTo: "To date",
      submit: "Print, download PDF"
    }
  },
  contextMenu: {
    addAppointment: "Appointment - {0}",
    addFromWaitingList: "Add from waiting list",
    addGeneralAppointment: "General appointment",
    patientCard: "Patient card",
    patientTreatmentPlan: "Patient treatment plan",
    sendQuestionary: "Send questionary",
    tasks: "Tasks",
    laboratoryWorks: "Laboratory works",
    appointments: "Appointments",
    family: "Family",
    payments: "Payments",
    addPayment: "Add payment",
    sendReminder: "Send reminder",
    appointmentLink: "Send an appointment link",
    sendMessage: "Send message",
    callToPatient: "Call to patient",
    changeGeneralAppointment: "To patient appointment",
    cameIn: "Came in",
    didNotCame: "Did not came",
    reminderApproved: "Reminder approved",
    cancelled: "Cancelled",
    clear: "Clear status",
    addTreatment: "Add treatment",
    copyAppointment: "Copy",
    pasteAppointment: "Paste",
    changeColor: "Change color",
    noDoctors: "Doctor not selected",
    cutAppointment: "Cut"
  },
  sendReminders: "Send reminders",
  waitingList: "Waiting list",
  settings: "Settings",
  addAppointment: "Add appointment",
  chooseDoctor: "Select doctor",
  generalAppointment: "General appointment",
  today: "Today",
  service: "Service",
  notes: "Notes",
  from: "From",
  duration: "Duration",
  status: "Status",
  reminders: "Reminders"
}

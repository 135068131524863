export default {
  head: {
    title: "MedForm - Reset Password",
    meta: [
      { name: "description", content: "MedForm reset password" }
    ]
  },
  title: "Reset Password",
  alert: "Enter your username and instructions will be sent to your email!",
  input: {
    username: "User name"
  },
  reset: "Reset",
  instructionsSent: "A password reset link has been sent if an account exists with the provided username. Please check your email.",
  remember: "Remember It?",
  signIn: "Sign In here"
}

export default {
  head: {
    title: "סוגי הוצאות",
    meta: [
      { name: "description", content: "סוגי הוצאות" }
    ]
  },
  validation: {
    preventDelete: "לא ניתן למחוק מסמך זה"
  },
  classifications: {
    // 1st group:
    group1: "עלות מכירות ושירותים",
    group1_l1: "שכר עבודה [1306]",
    group1_l2: "נילוות לשכר עבודה [1307]",
    group1_l3: "עבודות חוץ וקבלני משנה [1310]",
    group1_l4: "קניות סחורה בארץ [1320]",
    group1_l5: 'קניות חומרי גלם וחומרי בנייה בארץ ובחו"ל [1330]',
    group1_l6: 'קניית סחורות בחו"ל [1340]',
    group1_l7: "עלויות אחרות [1390]",
    // 2nd group:
    group2: "הוצאות מכירה",
    group2_l1: "שכר עבודה [3011]",
    group2_l2: "נילוות לשכר עבודה [3012]",
    group2_l3: "הוצאות הובלה ואחסנה [3015]",
    group2_l4: "הוצאות מחקר ופיתוח [3055]",
    group2_l5: "נסיעות [3060]",
    group2_l6: "אחזקת רכב והובלות (ללא דלק) [3066]",
    group2_l7: "הוצאות דלק [3067]",
    group2_l8: "החזר בלו על דלק [3068]",
    group2_l9: "דמי שכירות וניהול נכסים [3070]",
    group2_l10: "מכרזים, ירידים ותערוכות [3075]",
    group2_l11: "פחת [3080]",
    group2_l12: "בגדי עבודה [3085]",
    group2_l13: "שונות נטו [3090]",
    group2_l14: "עמלות וכרטיסי אשראי [3100]",
    group2_l15: "פרסום וקידום מכירות [3120]",
    // 3rd group:
    group3: "הוצאות הנהלה וכלליות",
    group3_l1: "שכר עבודה [3511]",
    group3_l2: "נילוות לשכר עבודה [3512]",
    group3_l3: "ביטוחים [3515]",
    group3_l4: "עבודות חוץ וקבלני משנה [3520]",
    group3_l5: "הוצאות ציוד מתכלה [3535]",
    group3_l6: "שירותים מקצועיים [3540]",
    group3_l7: "הוצאות אריזה [3545]",
    group3_l8: "הוצאות אחזקה ותיקונים [3550]",
    group3_l9: "הוצאות מחקר ופיתוח [3555]",
    group3_l10: "נסיעות [3560]",
    group3_l11: "אחזקת רכב והובלות (ללא דלק) [3566]",
    group3_l12: "הוצאות דלק [3567]",
    group3_l13: "החזר בלו על דלק [3568]",
    group3_l14: "דמי שכירות וניהול נכסים [3570]",
    group3_l15: "מיסים ואגרות [3575]",
    group3_l16: "פחת [3580]",
    group3_l17: "חשמל ומים [3590]",
    group3_l18: "שמירה וניקיון [3595]",
    group3_l19: "השתלמות וספרות מקצועית [3600]",
    group3_l20: "פרסום וקידום מכירות [3620]",
    group3_l21: "כיבודים, מתנות, תרומות, קנסות [3625]",
    group3_l22: "דמי ניהול [3640]",
    group3_l23: "הוצאות דואר ותקשורת [3650]",
    group3_l24: 'נסיעות לחו"ל [3660]',
    group3_l25: "הוצאות משפטיות [3665]",
    group3_l26: "משרדיות [3680]",
    group3_l27: "בגדי עבודה [3685]",
    // 4th group:
    group4: "הוצאות מימון",
    group4_l1: "לתאגידים בנקאיים [5010]",
    group4_l2: "אחרות [5090]",
    // 5th group:
    group5: "רכוש קבוע",
    group5_l1: "כלי רכב [8040]",
    group5_l2: "מחשבים וציוד עיבוד נתונים [8050]",
    group5_l3: "רהיטים ואביזרים [8060]",
    group5_l4: "מלאי בסיסי [8080]",
    group5_l5: "רכוש קבוע אחר [8090]"
  },
  buttons: {
    add: "הוספה",
    download: "הורדת קובץ",
    edit: "עריכה"
  },
  form: {
    edit: "עריכת סוג הוצאה",
    create: "הוספת סוג הוצאה",
    name: "תיאור",
    classification: "סיווג (קוד 6111)",
    nds: "אחוז מוכר למע\"מ",
    expenses: "אחוז מוכר למס הכנסה",
    // todo: add question tooltip #163
    nds_q: "יחושב מתוך סכום ההוצאה העסקית. להגדרת האחוז המוכר, מומלץ להתייעץ עם רו״ח",
    expenses_q: "יחושב מתוך סכום ההוצאה העסקית. להגדרת האחוז המוכר, מומלץ להתייעץ עם רו״ח",
    // todo: add question tooltip #163
    sortCode: "קוד מיון בתוכנה חיצונית (חשבשבת וכד')",
    accountKey: "מפתח חשבון בתוכנה חיצונית (חשבשבת וכד')",
  },
  table: {
    columns: {
      description: "שם",
      externalAccountKey: "מפתח חשבון",
      allowedForNds: '% מוכר למע"מ',
      allowedForExpenses: "% מוכר למס הכנסה"
    }
  }
}

export default {
  payUntil: "Pay until",
  documentDate: "Document date",
  createdDate: "Production date",
  createdBy: "created by",
  confirmationNumber: {
    label: "Confirmation number",
    error: ["Error getting confirmation number.", "Try again through actions menu or", "click here"]
  },
  draft: "Draft",
  customer: "Customer details",
  total: "Total",
  dealerNumber: "Dealer number",
  phone: "Phone",
  receiptDetails: "Receipt details",
  receiptTotal: "Receipt total",
  description: "Description",
  buttons: {
    header: "Operations",
    addRelatedDoc: "New document from this document",
    close: "Close the document",
    reopen: "Reopen the document",
    download: "Download the document",
    copy: "Duplication",
    linkedDocs: "Linked documents",
    cancel: "Cancellation of the document",
    delete: "Delete draft",
    edit: "Edit draft"
  },
  invoiceLineTable: {
    quantity: "Quantity",
    detail: "Details of services",
    amount: "Price",
    total: "Total",
    discount: "Discount",
    vat: "VAT 17.00%",
    payment: "Total payment",
    rounding: "Rounding"
  },
  receiptLineTables: {
    bankTransfer: {
      date: "Date",
      bankName: "Bank",
      bankBranch: "Branch",
      bankAccount: "Account",
      amount: "Amount"
    },
    cheque: {
      date: "Date",
      bankName: "Bank",
      bankBranch: "Branch",
      bankAccount: "Account",
      chequeNumber: "Number",
      amount: "Amount"
    },
    creditCard: {
      date: "Date",
      number4Digits: "last 4 digits",
      brand: "Brand",
      dealType: "Deal type",
      payNumber: "Payments number",
      amount: "Amount"
    },
    cash: {
      date: "Date",
      amount: "Amount"
    },
    wht: {
      date: "Date",
      amount: "Amount"
    }
  }
}

export default {
  head: {
    title: "תבניות טיפול",
    meta: [
      { name: "description", content: "תבניות טיפול" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      type: "סוג",
      code: "קוד טיפול",
      name: "שם תבנית טיפול",
      description: "תיאור",
      categoryName: "קטגוריית תבנית טיפול",
      materialPrice: "מחיר",
      active: "פעיל",
    }
  },
  filter: {
    category: "סנן לפי קטגוריה",
    code: "קוד טיפול",
    name: "חפש לפי שם תבנית טיפול"
  },
  title: "תבניות טיפול",
  reallyRemove: "האם אתה בטוחה שברצונך למחוק את {0}",
  addService: "הוספת תבנית טיפול / פריט",
  addTreatment: "הוספת תבנית טיפול",
  addItem: "הוספת פריט",
  saved: "השירות נשמר!",
  categories: "ניהול קטגוריות",
  suppliers: "ניהול ספקים",
  edit: "עריכה"
}

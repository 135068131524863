export default {
  head: {
    title: "משימה מספר",
    meta: [
      { name: "description", content: "משימה מספר" }
    ]
  },
  notesTable: {
    columns: {
      id: "#",
      createdAt: "תאריך ושעה",
      text: "הערה"
    }
  },
  addFile: "הוספת קובץ",
  addNote: "הוסיפו הערה",
  addNotes: "הוסף הערות",
  addedFile: "נוסף קובץ",
  assignee: "משוייך ל",
  created: "נוצרה",
  followUp: "תאריך מעקב",
  history: "היסטוריה",
  newTask: "הוספת משימה",
  noteSaved: "הערה נשמרה",
  notes: "הערות",
  patient: "מטופל",
  saveTaskBeforeUpload: "נא לשמור משימה לפני העלאת קבצים מצורפים",
  status: "סטטוס",
  taskSaved: "משימה נשמרה",
  title: "כותרת",
  validation: {
    title: "כותרת הינה חובה"
  }
};

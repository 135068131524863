export default {
  title: "Files",
  createNew: "Create New",
  folder: "Folder",
  file: "File",
  linkText: "Document scanning: {0}",
  cameraPhoto: "Photo from camera",
  rootFolder: "Root folder",
  search: "Search...",
  uploadFiles: "Upload files",
  open: "Open",
  rename: "Rename",
  remove: "Remove",
  send: "Send file",
  writeFolderName: "Please write folder name",
  errorFolderName: "Invalid folder name",
  writeNewName: "Please write new name",
  errorNewName: "Invalid new name",
  table: {
    columns: {
      name: "Name",
      dateModified: "Date modified",
      size: "Size",
    }
  },
  camera: {
    title: "Take the picture",
  },
  popup: {
    notification: "Message for patient file sent successfully",
    title: "Sending a file to the patient",
    subject: "Subject",
    message: "Message",
    validation: {
      subject: "Subject is required",
      message: "Message is required",
    }
  }
}

export default {
  title: "Minisite",
  validation: {
    tab: "Please fill form data",
    email: "Please enter valid email"
  },
  head: {
    title: "Minisite",
    meta: [
      { name: "description", content: "Minisite" }
    ]
  },
  formWizard: {
    nextBtn: "Next",
    backBtn: "Back",
    finishBtn: "Publish"
  },
  tabs: {
    businessInformation: {
      title: "Business information",
      businessName: "Business name",
      businessNameEnglish: "Business name in english",
      businessAddressShort: "Short business address",
      businessAddressFull: "Full business address"
    },
    contactDetails: {
      title: "Contact details",
      phone1: "Phone 1",
      phone2: "Phone 2",
      email: "Email",
      site: "Website link (if available)",
      socialNetworksList: "Social networks"
    },
    clinicInfo: {
      title: "Clinic info",
      text: "Text about clinic",
      service: "Service",
      services: "Services",
      addService: "Add service",
      serviceExist: "The service already exists in the list of services",
      workHours: "Work hours"
    },
    media: {
      title: "Media",
      businessLogo: "Clinic logo",
      businessBackround: "Background image",
      gallery: "Gallery",
      attachmentText: "Attachments (5 pictures max)",
      table: {
        columns: {
          createdAt: "Date",
          name: "File name"
        }
      }
    },
    finalStep: {
      title: "Final step"
    }
  }
}
import GraphqlService from "../GraphqlService";
import type JwtToken from "@/models/api/identity/JwtToken";
import type { Result } from "@/models/interfaces/Result";
import { LocalStore } from "@/utils";
import moment from "@/plugins/VueMomentPlugin";

export default class ApiAuthService {
  public get isSignedIn(): boolean {
    if (!this.token.access) {
      return false;
    }

    this.refreshTokenTask();
    this.activeControlTask();

    return Date.parse(this.token.expires) > Date.now();
  }

  public get token() {
    const token = LocalStore.get<JwtToken>("token");
    return token || <JwtToken>{ expires: new Date().toISOString() };
  }

  private async getToken(userName: string, password: string, dbName: string) {
    const { data } = await GraphqlService.queryGql<Result<JwtToken>>({
      method: "token",
      fields: ["data { access refresh lifetime expires }", "success", "message"],
      variables: [
        { field: "userName", value: userName, valueType: "String!" },
        { field: "password", value: password, valueType: "String!" },
      ],
      context: { headers: { "X-DBNAME": dbName } }
    });
    return data;
  }

  private async refreshToken() {
    const { data } = await GraphqlService.queryGql<JwtToken>({
      method: "refreshToken",
      fields: "access refresh lifetime expires",
      variables: [
        { field: "access", value: this.token.access, valueType: "String!" },
        { field: "refresh", value: this.token.refresh, valueType: "String!" },
      ]
    });
    return data;
  }

  public async signIn(userName: string, password: string, dbName: string) {
    const result = await this.getToken(userName, password, dbName);

    if (result && result.success && result.data) {
      LocalStore.set("token", { access: result.data.access, refresh: result.data.refresh, lifetime: result.data.lifetime, expires: result.data.expires });
      this.refreshTokenTask(true);
    }

    return result;
  }

  public signOut() {
    LocalStore.remove("token");
  }

  private _refreshTokenHandle: number | null = null;
  private refreshTokenTask(force?: boolean) {
    if (this._refreshTokenHandle && !force) return;
    if (!this.token.refresh) return;

    const timeout = (Date.parse(this.token.expires) - Date.now()) / 2;
    if (timeout < 0) return;
    this._refreshTokenHandle = window.setTimeout(async () => {
      const token = await this.refreshToken();
      if (token) {
        LocalStore.set("token", { access: token.access, refresh: token.refresh, lifetime: token.lifetime, expires: token.expires });
        this.refreshTokenTask(true);
      }
    }, timeout);
  }

  private _activeControlHandle: number | null = null;
  private activeControlTask() {
    if (this._activeControlHandle) return;

    this._activeControlHandle = window.setInterval(() => {
      const lifetime = this.token.lifetime;
      const active = LocalStore.get<Date>("active");

      if (!lifetime || !active) return;

      // if there is no activity
      if (moment(active).add(this.token.lifetime).isBefore()) {
        location.href = `/app/logout?redirectFrom=${encodeURIComponent(location.pathname)}`;
      }
    }, 1000);
  }

  //#region password
  public async resetPassword(username: string, dbName: string) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "resetPassword",
      variables: [
        { field: "username", value: username, valueType: "String!" },
      ],
      context: { headers: { "X-DBNAME": dbName } }
    });
    return data ?? false;
  }

  public async changePassword(email: string, resetToken: string, passwordNew: string, dbName: string) {
    const { data, error, message } = await GraphqlService.queryGql<boolean>({
      method: "changePassword",
      variables: [
        { field: "email", value: email, valueType: "String!" },
        { field: "resetToken", value: resetToken, valueType: "String!" },
        { field: "passwordNew", value: passwordNew, valueType: "String!" },
      ],
      context: { headers: { "X-DBNAME": dbName } }
    });
    return { success: data ?? false, error: message };
  }
  //#endregion
}

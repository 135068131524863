import { notify } from "@kyvg/vue3-notification";
import { useStores } from "@/stores";
import { i18n } from "@/locales/i18n";
import type { PopupOptions, NotifyOptions, ValidationMessages, IconType } from "@/models/interfaces";

export class Popup {
  static error(error: any, all: boolean = false) {
    function getMessage(): string {
      const err = Array.isArray(error) ? error[0] : error;
      if (typeof err === "string") return err;
      if (err?.message) return err.message;
      if (err?.error) return err.error;
      return error || i18n.global.t("default.error");
    }

    if (all && Array.isArray(error)) {
      const messages = [] as string[];
      error.forEach(x => {
        if (typeof x === "string") messages.push(x);
        else if (x?.message) messages.push(x.message);
        else if (x?.error) messages.push(x.error);
        else messages.push(i18n.global.t("default.error"));
      });
      this.notify({ text: messages.join("\r\n"), type: "error", duration: 10000 });
    } else {
      this.notify({ text: getMessage(), type: "error", duration: 10000 });
    }
  }

  static notify(options: NotifyOptions) {
    if (!options.duration) {
      if (options.type == "success") options.duration = 3000;
      if (options.type == "warn") options.duration = 4000;
      if (options.type == "error") options.duration = 5000;
    }
    notify(options);
  }

  static confirm(options: PopupOptions | {
    title?: string; message: string; height?: number | "auto"; width?: number; btnYes?: string; btnNo?: string;
    icon?: IconType; onConfirm?: () => void; onCancel?: () => void;
  }) {
    const stores = useStores();
    if ("message" in options) {
      const simpleOptions: PopupOptions = {
        type: "confirm",
        dialogWidth: options.width ?? 600,
        contentHeight: options.height ?? 180,
        header: {
          show: false
        },
        confirm: {
          title: options.title,
          message: options.message,
          btnYes: options.btnYes,
          btnNo: options.btnNo,
          icon: options.icon ?? "success",
          onConfirm: options.onConfirm,
          onCancel: options.onCancel
        }
      }
      stores.popup.showPopup(simpleOptions);
    } else {
      stores.popup.showPopup(options);
    }
  }

  static alert(options: PopupOptions | { msgText: string, btnText?: string, iconClass?: string, onClose?: () => void }) {
    const stores = useStores();
    if ("msgText" in options) {
      const simpleOptions: PopupOptions = {
        type: "alert",
        dialogWidth: 500,
        contentHeight: 100,
        header: {
          show: false
        },
        alert: {
          iconClass: options.iconClass,
          msgText: options.msgText,
          btnText: options.btnText,
          onClose: options.onClose
        }
      }
      stores.popup.showPopup(simpleOptions);
    } else {
      stores.popup.showPopup(options);
    }
  }

  static prompt(options: PopupOptions | {
    title: string, btnSubmit?: string; btnCancel?: string; model?: string; inputClass?: string;
    validation?: string | Array<[rule: string, ...args: any]>; messages?: ValidationMessages; onSubmit?: (param?: string) => void;
  }) {
    const stores = useStores();
    if ("title" in options) {
      const simpleOptions: PopupOptions = {
        type: "prompt",
        dialogWidth: 500,
        contentHeight: 110,
        header: {
          text: options.title
        },
        prompt: {
          btnSubmit: options.btnSubmit,
          btnCancel: options.btnCancel,
          model: options.model,
          textInputClass: options.inputClass,
          validation: options.validation,
          validationMessages: options.messages,
          onSubmit: options.onSubmit,
        }
      }
      stores.popup.showPopup(simpleOptions);
    } else {
      stores.popup.showPopup(options);
    }
  }
}

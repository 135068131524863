export default {
  head: {
    title: "גיבוי ושחזור",
    meta: [
      { name: "description", content: "גיבוי ושחזור" }
    ]
  },
  title: "גיבוי ושחזור",
  alertMessage: "תיקיית גיבוי: C:\\MedForm\\Backup",
  table: {
    columns: {
      id: "#",
      createdAt: "תאריך יצירה",
      fileName: "שם קובץ",
    }
  },
  actions: {
    backup: "גיבוי",
    restore: "שחזור",
  },
  popups: {
    restore: {
      title: "שחזור",
      alert: "שחזור יכול לקחת מעל 10 דקות. בבקשה לא לסגור עמוד זה.",
      startRestore: "תחילת שחזור",
    },
    backup: {
      title: "גיבוי",
      alert: "גיבוי יכול לקחת מעל 10 דקות. בבקשה לא לסגור עמוד זה.",
      startBackup: "התחלת גיבוי",
    }
  }
}
export default {
  head: {
    title: "Send reminders",
    meta: [
      { name: "description", content: "Send reminders" }
    ]
  },
  message: "Message",
  date: "Date",
  searchPlaceholder: "Search",
  filter: {
    appointmentTime: "Appointment",
    period: "Period",
    status: "Status",
    statuses: {
      all: "All",
      accepted: "Accepted",
      notAccepted: "Not accepted",
    }
  },
  buttons: {
    send: "Send reminder",
    approve: "Approve",
    message: "Message",
    cancel: "Cancel",
    view: "View"
  },
  popup: {
    title: "Send reminder",
    popupSuccessText: "Reminders sent, it can take the number of minutes depends on reminders number to receive it by client",
    sentToSelected: "Sending to {0} selected patients",
    sendToAll: "Sending to all {0} patients",
    templateTypeDefaultReminder: "Send default template",
    templateTypeDefaultRecall: "Send default template",
    templateTypeCustomTemplateReminder: "Send custom template message",
    templateTypeCustomTemplateRecall: "Send custom template message",
    templateTypeCustomMessage: "Send custom message",
    templateType: "Template type",
    message: "Message",
    messageValidation: "Message is required",
    subject: "Subject",
    subjectValidation: "Subject is required",
    reminderLinkType: "Link",
    reminderLinkTypeNone: "No link",
    reminderLinkTypeApproval: "Link to reminder",
    reminderLinkTypeChangeApparent: "Link to change the apparent",
    submit: "Send"
  },
  table: {
    columns: {
      fullName: "Full name",
      defaultDoctorName: "Doctors",
      actions: "Actions",
      appointmentTime: "Appointment",
      serviceName: "Service",
      approved: "Approved",
      history: "History"
    }
  },
  view: {
    doctors: "Doctors",
    appointment: "Appointment",
    allDoctors: "All doctors",
  }
}

export default {
  head: {
    title: "Service",
    meta: [
      { name: "description", content: "Service" }
    ]
  },
  createService: "Create service",
  updateService: "Update service {0}",
  createItem: "Create item",
  updateItem: "Update item {0}",
  serviceCategory: "Treatment category",
  itemCategory: "Item category",
  serviceName: "Treatment template name",
  itemName: "Item template name",
  serviceCode: "Treatment code",
  itemCode: "Item code",
  serviceActive: "Treatment is active",
  itemActive: "Item is active",
  emailSubject: 'Email subject',
  description: "Description",
  appointmentMinutes: "Number of appointment minutes",
  materialPrice: "Material price",
  recallMessageAutomatically: "Allow sending an automatic recall message",
  recallDays: "Service recall days",
  message: "Message",
  allowWebpageBooking: "Allow to book on webpage",
  selectOption: "Select option",
  sendWithWhatsapp: "Send with Whatsapp",
  sendWithSms: "Send with SMS",
  sendWithEmail: "Send with Email",
  notesInReminder: "Instructions before treatment",
  notesInFollowUp: "Instructions after treatment",
  sendFollowUpMessage: "Send follow up message after 72 hours",
  validation: {
    exists: "Service exist in database.",
  }
}

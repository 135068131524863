import GraphqlService from "../GraphqlService";
import { userSchemaAllFields as fields, type UserSchema } from "@/models/schemas";
import type { TabulatorParams, Result } from "@/models/interfaces";
import type { SaveUserInput } from "@/models/api/mutations/UserModels";

export default class UserService {
  //#region Query
  async getMe() {
    const { data } = await GraphqlService.queryGql<UserSchema>({ method: "me", fields: fields })
    return data;
  }

  async getUsers(params: TabulatorParams) {
    return await GraphqlService.getItems<UserSchema>("users", fields, params);
  }

  async getUsersAll() {
    const { data } = await GraphqlService.queryGql<UserSchema[]>({
      method: "usersAll",
      fields: ["id", "fullName", "userImage"],
      order: [{ field: "fullName", value: "ASC" }]
    });
    return data ?? [];
  }

  async getUsersWithAttendancePermission() {
    const { data } = await GraphqlService.queryGql<UserSchema[]>({
      method: "usersWithAttendancePermission",
      fields: fields,
      order: [{ field: "fullName", value: "ASC" }]
    });
    return data;
  }

  async getUser(id: number) {
    return await GraphqlService.getItem<UserSchema>("user", fields, id);
  }
  //#endregion

  //#region Mutation
  async saveUser(input: SaveUserInput) {
    return await GraphqlService.setItem<UserSchema>("saveUser", "user", fields, input);
  }

  async updateUserPassword(id: number, password: string) {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "updateUserPassword",
      fields: `success message`,
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "password", value: password, valueType: "String!" }
      ]
    });
    return data;
  }

  async deleteUser(id: number) {
    return await GraphqlService.deleteItem("deleteUser", id);
  }

  async setActiveValueForUser(id: number, active: boolean) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setActiveValueForUser",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "active", value: active, valueType: "Boolean!" }
      ]
    });
    return data ?? false;
  }
  //#endregion
}

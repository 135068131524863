export default {
  head: {
    title: "עבודות מעבדה",
    meta: [
      { name: "description", content: "תיאור עבודות מעבדה" }
    ]
  },
  notesTable: {
    columns: {
      id: "#",
      createdAt: "נוצר ב",
      text: "הערות"
    }
  },
  addFile: "הוספת קובץ",
  saveLaboratoryWorkBeforeUpload: "נא לשמור עבודות מעבדה לפני העלאת קבצים מצורפים",
  addNote: "הוסף הערה",
  addNotes: "הוסף הערה",
  addedFile: "נוסף קובץ",
  created: "תאריך רישום",
  followUp: "מועד סיום",
  pickupDate: "תאריך מסירה",
  deliveryDate: "מועד חזרה בפועל",
  fileNumber: "מספר תיק",
  techniciansName: "שם טכנאי",
  history: "הסטוריה",
  laboratoryWorkSaved: "עבודת מעבדה נשמרה",
  newLaboratoryWork: "הוספת עבודת מעבדה",
  noteSaved: "הערה נשמרה",
  notes: "הערות",
  patient: "מטופל",
  status: "סטטוס",
  laboratory: "מעבדה",
  title: "תיאור עבודה",
  teethQuantity: "כמות",
  teethData: "בחר שיניים",
  teethType: "סוג שן",
  doctor: "רופא",
  hide: "להסתיר"
};

export default {
  head: {
    title: "Suppliers",
    meta: [
      { name: "description", content: "Suppliers description" }
    ]
  },
  validation: {
    preventDeleteSupplier: "This supplier has expenses and cannot be deleted."
  },
  buttons: {
    add: "Add supplier",
    download: "Download",
    edit: "Edit"
  },
  options: {
    all: "All",
    active: "Active",
    notActive: "Not active"
  },
  search: {
    placeholder: "Search",
    tooltip: "Search by: name, tax id"
  },
  form: {
    edit: "Edit supplier",
    create: "Create supplier",
    name: "Name",
    taxId: "Tax id",
    department: "Department",
    active: "Active",
    contacts: "Contacts",
    contactPerson: "Contact person",
    phone1: "Phone 1",
    phone2: "Phone 2",
    email1: "Email 1",
    email2: "Email 2",
    email3: "Email 3",
    fax: "Fax",
    address: "Address",
    city: "City",
    zip: "Zip",
    country: "Country",
    account: "Account",
    paymentTerms: "Payment terms",
    supplierKey: "Supplier key",
    bankName: "Bank name",
    bankBranch: "Bank branch",
    bankAccount: "Bank account",
    notes: "Notes",
  },
  paymentTerms: {
    immediate: "Immediate",
    currentMonth: "Current month",
    currentMonth10: "Current month + 10",
    currentMonth30: "Current month + 30",
    currentMonth60: "Current month + 60",
    currentMonth90: "Current month + 90"
  },
  table: {
    columns: {
      title: "Name",
      taxId: "Tax ID",
      contactPerson: "Contact person",
      phone: "Phone",
      email1: "Email",
      active: "Active"
    }
  }
}

export default {
  head: {
    title: "Task",
    meta: [
      { name: "description", content: "Task description" }
    ]
  },
  notesTable: {
    columns: {
      id: "ID",
      createdAt: "Created",
      text: "Note"
    }
  },
  addFile: "Add File",
  addNote: "Add Note",
  addNotes: "Add Notes",
  addedFile: "Added file",
  assignee: "Assignee",
  created: "Created",
  followUp: "Follow up",
  history: "History",
  newTask: "New Task",
  noteSaved: "Note saved",
  notes: "Notes",
  patient: "Patient",
  saveTaskBeforeUpload: "Please save task before uploading attachments",
  status: "Status",
  taskSaved: "Task saved",
  title: "Title",
  validation: {
    title: "Title is required."
  }
};

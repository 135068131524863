import type { DateTimeString } from "@/models/interfaces";
import type { MessageProvider, MedicalFormStatus } from "@/models/enums";

export interface MedicalFormSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  userId: number;
  patientId: number;
  templateId: number;
  messageProvider: MessageProvider;
  messageId: number;
  status: MedicalFormStatus;

  filledUpAt?: DateTimeString;

  doctorName: string;
  templateTitle: string;
}

export const medicalFormSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  userId
  patientId
  templateId
  messageProvider
  messageId

  status

  filledUpAt

  doctorName
  templateTitle
`;

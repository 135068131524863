export default {
  head: {
    title: "MedForm",
    meta: [
      { name: "description", content: "כניסה ל MedForm" }
    ]
  },
  welcome: "ברוכים הבאים!",
  signIn: "התחבר כדי להתחיל",
  input: {
    userName: "שם משתמש",
    password: "סיסמה",
    dbName: "מאגר מידע"
  },
  dbSelect: "בחר מסד נתונים",
  logIn: "התחבר",
  forgot: "שכחת ססמה?",
  reset: "לאפס את הסיסמה",
  user_wrong_data: "שם משתמש או סיסמא אינם נכונים",
  user_inactive: "משתמש לא פעיל",
  user_password_expiry: "סיסמתך פגה תוקף",
  license: {
    expired: "רשיון פג תוקף. בבקשה לפנות לתמיכה.",
    notFound: "רשיון לא נמצא. בבקשה לפנות לתמיכה."
  }
}

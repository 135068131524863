import GraphqlService from "../GraphqlService";
import type { Result } from "@/models/interfaces";

export default class TaxService {
  public async getTaxInvoiceAmount() {
    const { data } = await GraphqlService.queryGql<number>({ method: "taxInvoiceAmount" });
    return data!;
  }

  public async getTaxLoginLink() {
    const { data } = await GraphqlService.queryGql<string>({ method: "taxLoginLink" });
    return data!;
  }

  public async taxTokenRemove() {
    const { data } = await GraphqlService.mutateGql<boolean>({ method: "taxTokenRemove" });
    return data!;
  }

  public async taxInvoiceApproval(incomeId: number) {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "taxInvoiceApproval",
      fields: "success message exception",
      variables: [
        { field: "incomeId", value: incomeId, valueType: "Int!" },
      ]
    });
    return data;
  }
}

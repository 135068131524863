import ConfigService from "@/services/ConfigService";
import { HttpService } from "@/services/HttpService";
import { useStores } from "@/stores";
import { StorageFileType, MessageProvider } from "@/models/enums";
import type { SendPatientPlanMessageInput } from "@/models/api/mutations/PatientModels";
import type { StorageFileInfo, Result } from "@/models/interfaces";

export default class StorageService {
  public async sendTreatmentPlanFiles(contents: Blob[], input: SendPatientPlanMessageInput) {
    function getPlatformId(platform: MessageProvider) {
      switch (platform) {
        case MessageProvider.None: return 0;
        case MessageProvider.Whatsapp: return 1;
        case MessageProvider.SMS: return 2;
        case MessageProvider.Email: return 3;
        default: throw new Error(`Wrong enum member, value: [${platform}]`);
      }
    }

    const data = new FormData();
    contents.forEach((file) => data.append("files", file));
    data.set("message", input.message);
    data.set("title", input.title);
    data.set("planId", input.planId.toString());
    data.set("platform", getPlatformId(input.platform).toString());

    return await HttpService.post<Result, FormData>("storage/files/send-treatment-plan-files", { data, useAuth: true });
  }

  public async uploadSettingImage(content: Blob, key: string, newFileName?: string) {
    const data = new FormData();
    data.set("file", content);

    return await HttpService.post<boolean, FormData>(
      newFileName ? `storage/files/upload-image/${key}?newFileName=${newFileName}` : `storage/files/upload-image/${key}`, { data, useAuth: true }
    );
  }

  public async uploadSettingPdf(content: Blob, key: string) {
    const data = new FormData();
    data.set("file", content);

    return await HttpService.post<boolean, FormData>(`storage/files/upload-pdf-file/${key}`, { data, useAuth: true });
  }

  public async uploadFile(content: Blob, fileType: StorageFileType, entityId: number) {
    const data = new FormData();
    data.set("file", content);
    data.set("fileType", fileType.toString());
    data.set("entityId", entityId.toString());

    return await HttpService.post<StorageFileInfo, FormData>("storage/files/upload", { data, useAuth: true });
  }

  public async uploadPatientFile(patientId: number, content: Blob, relativeName: string) {
    const data = new FormData();
    data.set("file", content);
    data.set("relativeName", relativeName);

    return await HttpService.post<StorageFileInfo, FormData>(`storage/files/patients/${patientId}/upload`, { data, useAuth: true });
  }

  private getFilePath(fileType: StorageFileType, fileName: string, entityId?: number | string) {
    const stores = useStores();
    switch (fileType) {
      case StorageFileType.PatientPhoto:
      case StorageFileType.PatientPhotoId:
        return `${stores.database.dbName}/patient-image/${fileName}`;
      case StorageFileType.DoctorPhoto:
      case StorageFileType.DoctorPhotoId:
        return `${stores.database.dbName}/doctor-image/${fileName}`;
      case StorageFileType.TaskAttachment:
      case StorageFileType.TaskAttachmentId:
        return `${stores.database.dbName}/tasks/${fileName}`;
      case StorageFileType.LaboratoryWorkAttachment:
      case StorageFileType.LaboratoryWorkAttachmentId:
        return `${stores.database.dbName}/laboratory/${fileName}`;
      case StorageFileType.MessageTemplateAttachment:
      case StorageFileType.MessageTemplateAttachmentId:
        return `${stores.database.dbName}/message-template/${fileName}`;
      case StorageFileType.PatientFiles:
      case StorageFileType.PatientFilesId:
        return `${stores.database.dbName}/patient-files/${entityId}/${fileName}`;
      case StorageFileType.PatientFilesFromChat:
      case StorageFileType.PatientFilesFromChatId:
        return `${stores.database.dbName}/chat/${entityId}/${fileName}`;
      case StorageFileType.ExpenseFiles:
      case StorageFileType.ExpenseFilesId:
        return `${stores.database.dbName}/expenses/${entityId}/${fileName}`;
      case StorageFileType.LeadAttachment:
      case StorageFileType.LeadAttachmentId:
        return `${stores.database.dbName}/leads/${fileName}`;
      case StorageFileType.IncomePdf:
      case StorageFileType.IncomePdfId:
        return `${stores.database.dbName}/incomes/pdf/${fileName}`;
      case StorageFileType.EmailAttachment:
      case StorageFileType.EmailAttachmentId:
        return `${stores.database.dbName}/email-attachments/${fileName}`;
      case StorageFileType.MinisiteImages:
      case StorageFileType.MinisiteImagesId:
        return `${stores.database.dbName}/minisite/${fileName}`;
      case StorageFileType.MinisiteGalleryPhoto:
      case StorageFileType.MinisiteGalleryPhotoId:
        return `${stores.database.dbName}/minisite/gallery/${fileName}`;
      case StorageFileType.RootSettingImages:
      case StorageFileType.RootSettingImagesId:
      default:
        return `${stores.database.dbName}/${fileName}`;
    }
  }

  public getLink(fileType: StorageFileType, path?: string | null, entityId?: number | string, download?: boolean): string {
    if (!path) return '';
    if (path.startsWith('http')) return path;

    const query = download == true ? "?download=true" : "";
    const filePath = this.getFilePath(fileType, path, entityId);
    return ConfigService.api(`storage/files/${filePath}${query}`);
  }

  public downloadFile(fileType: StorageFileType, fileName: string, entityId: number, blank?: boolean) {
    const link = document.createElement("a");
    link.href = this.getLink(fileType, fileName, entityId, true);
    if (blank) {
      link.target = "_blank";
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public async downloadEmailFile(messageId: string, fileId: string, fileName: string, folderId: string | null) {
    const data = new FormData();
    data.set("messageId", messageId);
    data.set("fileId", fileId);
    data.set("fileName", fileName)
    const response = await HttpService.postRequest<Blob, FormData>(`storage/files/email${folderId ? `?folderId=${folderId}` : ""}`, {
      data: data,
      responseType: 'blob',
      validateStatus: (s) => s <= 500,
      useAuth: true
    });
    if (response.status === 200) {
      const blob = response.data;
      const link = document.createElement("a");
      link.download = fileName;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // https://github.com/axios/axios/issues/3779#issuecomment-1002739087
      console.log(await response.data.text());
      const errorMessage = response.statusText || 'Unknown error';
      throw new Error(`Error: ${response.status} - ${errorMessage}`);
    }
  }

  /*
  public async deleteFile(folderWithoutDbNameAndFileName: string) {
    return await HttpService.delete<boolean>(`storage/files/${stores.database.dbName}/${folderWithoutDbNameAndFileName}`, {
      useAuth: true
    });
  }
  */
}

import type { TimeSpanString } from "@/models/interfaces";
import type { DayOfWeek, SocialNetwork } from "@/models/enums";

//#region BaseData
interface ClinicInfoBase {
  text: string;
  services: string[];
}
//#endregion

//#region SecondaryData

// models:
export interface WorkHour {
  weekDay: DayOfWeek;
  fromHour: TimeSpanString;
  toHour: TimeSpanString;
}

// inputs:
export type WorkHourInput = WorkHour;
//#endregion

//#region PrimaryData

// models:
export interface Minisite {
  information: BusinessInformation;
  details: ContactDetails;
  info: ClinicInfo;
  media: Media;
}

export interface BusinessInformation {
  businessName: string;
  businessNameEnglish: string;
  businessAddressShort: string;
  businessAddressFull: string;
}

export interface ContactDetails {
  phone1: string;
  phone2: string;
  email: string;
  site: string;
  socialNetworksList: SocialNetwork[];
}

export interface ClinicInfo extends ClinicInfoBase {
  workHours: WorkHour[];
}

export interface Media {
  businessLogo: string;
  businessBackround: string;
  gallery: string[];
}

// inputs:
export type MinisiteInput = {
  information: BusinessInformationInput;
  details: ContactDetailsInput;
  info: ClinicInfoInput;
  media: MediaInput;
};
export type BusinessInformationInput = BusinessInformation;
export type ContactDetailsInput = ContactDetails;
export type ClinicInfoInput = ClinicInfoBase & { workHours: WorkHourInput[]; };
export type MediaInput = Media;
//#endregion

//#region Fields
export const mediaFields = `
  businessLogo
  businessBackround
  gallery
`;

export const minisiteFields = `
  information {
    businessName
    businessNameEnglish
    businessAddressShort
    businessAddressFull
  }
  details {
    phone1
    phone2
    email
    site
    socialNetworksList
  }
  info {
    text
    services
    workHours {
      weekDay
      fromHour
      toHour
    }
  }
  media {${mediaFields}}
`;
//#endregion
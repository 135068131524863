<template>
  <PrimePopup ref="primePopup" />
  <notifications :position="notificationsPosition" />
  <div v-if="loading && !stores.layout.errorCode">
    <Spinner height="100vh" />
  </div>
  <div v-else>
    <RouterView />
  </div>
</template>

<script setup lang="ts">
  import Spinner from "@/components/spinner.vue";
  import PrimePopup from "@/components/popups/PrimePopup.vue";
  import { ref, computed, onMounted, watch } from "vue";
  import { IdentityHandler } from "@/services/IdentityHandler";
  import { Popup } from "@/utils";
  import { useStores } from "@/stores";
  import { useI18n } from "vue-i18n";

  const stores = useStores();
  const { locale } = useI18n();

  const loading = ref(true);
  const primePopup = ref<InstanceType<typeof PrimePopup>>();

  const notificationsPosition = computed(() => (locale.value == "he" ? "bottom right" : "bottom left"));

  watch(
    () => stores.popup.isVisible,
    (visible) => {
      if (visible && stores.popup.options) {
        primePopup.value?.show(stores.popup.options);
      }
    }
  );
  onMounted(async () => {
    try {
      await IdentityHandler.initializeAsync();
      loading.value = false;
    } catch (error) {
      Popup.error(error);
      console.log(error);
    }
  });
</script>

export default {
  head: {
    title: "Income",
    meta: [
      { name: "description", content: "Income" }
    ]
  },
  descriptionLegend: "Document description",
  documentDetailsLegend: "Document details",
  documentDate: "Document date",
  dueDate: "Due date",
  patient: "Patient",
  emailLegend: "Sending the document by email",
  patientEmails: "Sending the original document by email (up to 3 email addresses can be filled in)",
  emailContent: "Email content",
  paymentLegend: "Receiving payment online",
  paymentAllow: "Add a payment button to the email sent to the customer.",
  paymentMaxNumber: "Max payments",
  discount: "Discount",
  vat: "Vat",
  rounding: "Round total",
  total: "Total to pay",
  subtotal: "Sub total",
  notesLegend: "Additional notes at the bottom of the document",
  documentFooter: "Here you can add any important information about the treatments or billing",
  vatTypeLabel: "Vat type",
  languageLabel: "Language",
  submit: "Document production",
  preview: "Preview",
  draft: "Save a draft",
  addReceiptItem: "Add an item",
  addInvoiceItem: "Add service item",
  addService: "Add service",
  services: "Services",
  addTreatment: "Add treatment",
  invoiceTableHeader: "Details of invoices",
  receiptTableHeader: "Details of receipts",
  receiptTableMessage: "How did you get paid? If you were paid in several forms of payment, you can choose several types of receipts.",
  actions: "Actions",
  totalReceipt: "Total",
  bankBranch: "Branch",
  bankAccount: "Invoice",
  chequeNumber: "Tax",
  vatType: {
    NONE: "None",
    NORMAL: "Normal",
    NO_VAT: "No vat"
  },
  language: {
    NONE: "None",
    ENGLISH: "English",
    HEBREW: "Hebrew",
  },
  discountType: {
    NONE: "None",
    PERCENT: "Percent",
    AMOUNT: "Amount",
  },
  backPopup: {
    text: "Continue without saving? You can save the document as a draft and come back to it later",
    confirm: "Yes, go back",
    cancel: "Continue editing"
  },
  validation: {
    form: "You have not filled out all the required data in the document",
    service: "Service is required",
    draft: "Not all fields valid",
    patient: "A patient must be selected",
    discountValue: "Check discount",
    quantity: "Count is required",
    itemPrice: "Price is required",
    invoiceReceipt: "The sum of invoice lines should be the same as the sum of receipts.",
    notSentToPatient: "The document saved but not send to patient!"
  },
  invoiceLineTable: {
    treatment: "Client treatments",
    description: "Description",
    quantity: "Count",
    itemPrice: "Item price",
    vat: "Vat",
    amount: "Total"
  },
  receiptLineTable: {
    // common
    date: "Date",
    amount: "Amount",
    type: "Type",
    details: "Details",
    // bankTransfer
    transferBankName: "Bank",
    transferBankBranch: "Branch",
    transferBankAccount: "Account",
    // cheque
    chequeBankName: "Bank",
    chequeBankBranch: "Branch",
    chequeBankAccount: "Account",
    chequeNumber: "Number",
    // creditCard
    number4Digits: "last 4 digits",
    brand: "Brand",
    dealType: "Deal type",
    payNumber: "Pay number",
    // other
    selectBank: "Select bank...",
  },
  payment: {
    button: "Credit card charge",
    modal: {
      errorNegative: "Balance amount must be positive.",
      title: "Billing the customer on credit (clearance)",
      alert: [
        "Important to read before billing!",
        "Immediately after the credit is cleared, the document will be automatically generated and sent to the customer. Before this operation, make sure that you have entered all the details of sending by email and additional receipts if there were any. You will not be able to view the document before it is produced or go back and correct details."
      ],
      method: "Method of billing",
      methods: {
        form: "Credit form",
        terminal: "Credit terminal"
      },
      sum: "Amount",
      maxPayments: "Maximum payments",
      continue: "Continue payment",
      back: "Back to edit",
      success: "Payment Succeeded",
      fail: "Payment Failed",
    }
  }
}

import treatment from "../patients/treatments/treatment";

export default {
  head: {
    title: "Collection report",
    meta: [
      { name: "description", content: "Collection report description" }
    ]
  },
  title: "Collection report",
  total: "Total",
  chargesTitle: "Open unpaid charges",
  clientsTitle: 'Main clients',
  barChartLabel: "incomes",
  dueDateTo: "Due date",
  clients: {
    title: "Patient list",
    table: {
      columns: {
        fullName: "Client name",
        total: "Balance due"
      }
    }
  },
  charges: {
    title: "Open charges",
    treatment: "Treatment",
    treatmentPlan: "Treatment plan",
    table: {
      columns: {
        financialNumber: "No.",
        documentDate: "Date",
        dueDate: "Pay until",
        incomeType: "Type of document",
        patientName: "Client name",
        description: "Description",
        payAmount: "Amount",
      }
    }
  },
  actions: {
    notify: "Notify client",
    downloadExcel: "Download Excel",
    downloadPdf: "Download PDF",
    view: "View",
    add: "Add",
    send: "Send"
  }
}

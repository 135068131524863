<template>
  <div class="p-formkit">
    <AutoComplete
      ref="autoComplete"
      :virtualScrollerOptions="scrollerOptions"
      :id="context.id"
      v-model="option"
      :disabled="attrs._disabled ?? context.disabled ?? false"
      :tabindex="attrs.tabindex"
      :aria-label="attrs.ariaLabel"
      :aria-labelledby="attrs.ariaLabelledby"
      :suggestions="suggestions"
      :dropdown="attrs?.dropdown ?? false"
      :multiple="attrs.multiple ?? false"
      :pt="attrs.pt"
      :pt-options="attrs.ptOptions"
      :unstyled="attrs.unstyled ?? false"
      @complete="search"
      @change="handleInput"
      @show="visible = true"
      @hide="visible = false"
      :option-label="attrs.optionLabel ?? 'label'"
      :input-class="styleClass">
      <template #option="slotProps">
        <div class="d-flex gap-1 align-items-center" :title="slotProps.option.label">
          <img v-if="slotProps.option.image" :alt="slotProps.option.label" :src="slotProps.option.image" class="rounded-circle avatar-xxs" />
          <div v-else-if="haveImages" class="avatar-xxs">
            <span class="avatar-title rounded-circle">{{ slotProps.option.label?.substring(0, 1) }}</span>
          </div>
          <div class="text-truncate" :style="`max-width: calc(${elementWidth} - 24px - 1.25rem - 20px)`">{{ slotProps.option.label }}</div>
        </div>
      </template>
      <template #empty>
        <span>{{ queryMode ? query : t("default.notFound") }}</span>
      </template>
    </AutoComplete>
  </div>
</template>

<!-- https://github.com/sfxcode/formkit-primevue/blob/main/src/components/PrimeAutoComplete.vue -->

<script setup lang="ts">
  import AutoComplete, { type AutoCompleteCompleteEvent } from "primevue/autocomplete";
  import type { VirtualScrollerProps } from "primevue/virtualscroller";
  import { ref, computed, onMounted, onBeforeUnmount, watch, type WatchStopHandle } from "vue";
  import { useI18n } from "vue-i18n";
  import { HtmlHelper } from "@/utils";

  const { t } = useI18n();
  const props = defineProps<{
    context: any;
  }>();

  const context = props.context;
  const attrs = computed(() => context?.attrs);

  function setValue() {
    if (options.value.length && context._value) {
      option.value = options.value.find((option: any) => option["value"] == context._value);
    } else {
      option.value = undefined;
    }
  }

  const visible = ref(false);
  const unwatchOptions = ref<WatchStopHandle>();

  onMounted(() => {
    // https://stackoverflow.com/a/37808429/22548940
    Object.defineProperty(context, "query", {
      get: function () {
        return query.value;
      },
      set: function (value: string) {
        option.value = query.value = value;
        handleInput({ originalEvent: null, value: value });
      }
    });
    setValue();
    unwatchOptions.value = watch(options, () => setValue());
    // show on click by input
    HtmlHelper.findAll<HTMLInputElement>("input.p-autocomplete-input", autoCompleteElement.value).forEach((input) => {
      input.addEventListener("click", () => {
        if (!visible.value) {
          HtmlHelper.findFirst<HTMLButtonElement>("button.p-autocomplete-dropdown", autoCompleteElement.value)?.click();
        }
      });
      input.addEventListener("keydown", (e) => {
        if (e.key == "Enter" && suggestions.value.length > 0) {
          option.value = suggestions.value[0];
          handleInput({ originalEvent: e, value: suggestions.value[0].label });
        }
      });
    });
  });

  onBeforeUnmount(() => {
    if (unwatchOptions.value) unwatchOptions.value();
  });

  watch(
    () => context._value,
    () => setValue()
  );

  const option = ref();
  const query = ref("");
  const suggestions = ref<any>([]);
  const options = computed(() => attrs.value.options);
  const queryMode = computed(() => attrs.value.queryMode);
  const haveImages = computed(() => options.value.findIndex((option: any) => option?.image) >= 0);
  // https://primevue.org/autocomplete/#virtualscroll
  const scrollerOptions = computed<VirtualScrollerProps | undefined>(() => (suggestions.value.length > 100 ? { itemSize: 40 } : undefined));
  const autoComplete = ref<AutoComplete>();
  const autoCompleteElement = computed<HTMLDivElement>(() => (autoComplete.value as any).$el);
  const elementWidth = computed(() => autoCompleteElement.value.clientWidth + "px");

  function search(event: AutoCompleteCompleteEvent) {
    query.value = event.query;
    if (event.query?.length) {
      suggestions.value = options.value.filter((option: any) => option[attrs.value.optionLabel ?? "label"].toLowerCase().includes(event.query.toLowerCase()));
    } else {
      suggestions.value = options.value.filter(Boolean);
    }
  }

  function handleInput(event: { originalEvent: any; value: string }) {
    const currentValue = typeof option.value === "string" ? undefined : option.value["value"];
    const defaultValue = attrs.value.defaultValue ?? null;

    if (currentValue === undefined) {
      context?.node.input(defaultValue);
    } else {
      context?.node.input(currentValue);
    }
  }

  const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid ? `${attrs.value?.class} p-invalid` : attrs.value?.class));
</script>

<style lang="scss">
  .p-autocomplete {
    width: 100%;

    .p-autocomplete-dropdown {
      padding: 0.25rem 0;
    }
  }

  .p-autocomplete-panel {
    z-index: 10000 !important;

    .p-autocomplete-items {
      padding: 0;
      margin: 0;

      .p-autocomplete-item {
        padding: 0.5rem;
      }
    }
  }

  @media (min-width: 1200px) {
    .p-autocomplete-panel {
      max-height: 370px !important;
    }
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #212529;
  }
</style>

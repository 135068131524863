export default {
  head: {
    title: "Laboratory work",
    meta: [
      { name: "description", content: "Laboratory work description" }
    ]
  },
  notesTable: {
    columns: {
      id: "ID",
      createdAt: "Created",
      text: "Note"
    }
  },
  addFile: "Add File",
  saveLaboratoryWorkBeforeUpload: "Please save laboratory work before uploading attachments",
  addNote: "Add Note",
  addNotes: "Add Notes",
  addedFile: "Added file",
  created: "Date of registration",
  followUp: "End date",
  pickupDate: "Pickup date",
  deliveryDate: "Delivery date",
  fileNumber: "File number",
  techniciansName: "A technician's name",
  history: "History",
  laboratoryWorkSaved: "Laboratory work saved",
  newLaboratoryWork: "New laboratory work",
  noteSaved: "Note saved",
  notes: "Notes",
  patient: "Patient",
  status: "Status",
  laboratory: "Laboratory",
  title: "Job description",
  teethQuantity: "Number of items",
  teethData: "Teeth selection",
  teethType: "Teeth type",
  doctor: "Doctor",
  hide: "Hide"
};

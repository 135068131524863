export default {
  head: {
    title: "Email",
    meta: [
      { name: "description", content: "Email" }
    ]
  },
  settings: {
    head: "Email settings",
    email: "Email",
    userName: "User name",
    password: "Password",
    server: "Server",
    port: "Port",
    useSSL: "Use SSL connect",
    connectAndSave: "Connect and save",
    checkSmtp: "Check SMTP",
    checkImap: "Check IMAP",
    gmail: "Gmail",
    outlook: "Outlook",
    custom: "Custom",
    imap: "IMAP (incoming)",
    smtp: "SMTP (outgoing)",
    connect: "Connect",
    submitedConnection: "{0} account successfully connected.",
    disconnect: "Disconnect",
    imapSuccess: "Successful IMAP connection",
    imapError: "Something went wrong with IMAP connection",
    smptpError: "Something went wrong with SMTP connection",
    smtpSuccess: "Successful SMTP connection",
    smtpTestSubject: "Testing SMTP"
  },
  send: {
    head: "Email send",
    send: "Send",
    filesHelpText: "Upload attachment files via drag and drop by this area"
  },
  list: {
    empty: "No emails to display",
    settingTooltip: "Settings",
    markAsRead: "Mark as read",
    markAsUnread: "Mark as unread",
    showing: "Showing",
    of: "of"
  },
  patientsFilesPopup: {
    title: "Please select patient and his folder",
    emailMessagePatients: "Email message patients:",
    patient: "Patient",
    folder: "Folder",
    validation: {
      patient: "A patient must be selected"
    }
  },
  compose: 'Compose',
  inbox: 'Inbox',
  starred: 'Starred',
  draft: 'Draft',
  sentMail: 'Sent Mail',
  trash: 'Trash',
  labels: 'Labels',
  download: 'Download',
  reply: 'Reply',
  replyAll: 'Reply All',
  forward: 'Forward',
  emailSent: "Email was sent successfully",
  saveInPatientFolder: "Save in patient folder"
}
export default {
  head: {
    title: "MedForm - שחכת סיסמה",
    meta: [
      { name: "description", content: "MedForm שחכת סיסמה" }
    ]
  },
  title: "שחכת סיסמה",
  alert: "הכניסו שם משתמש ונשלח אליכם דוא״ל לאיפוס הסיסמה",
  input: {
    username: "שם משתמש"
  },
  reset: "שלח",
  instructionsSent: "נשלח קישור לאיפוס הסיסמה אם קיים חשבון עם שם המשתמש שסופק. אנא בדוק את הדואר האלקטרוני שלך.",
  remember: "תזכור את זה?",
  signIn: "להתחבר"
}

export default {
  title: "האתר שלי",
  validation: {
    tab: "נא להשלים את הפרטים החסרים",
    email: 'אנא הכניסו כתובת דוא"ל תקינה'
  },
  head: {
    title: "האתר שלי",
    meta: [
      { name: "description", content: "האתר שלי" }
    ]
  },
  formWizard: {
    nextBtn: "הבא",
    backBtn: "חזרה",
    finishBtn: "יצירת האתר!"
  },
  tabs: {
    businessInformation: {
      title: "פרטי בית העסק",
      businessName: "שם בית העסק",
      businessNameEnglish: "שם בית העסק באנגלית",
      businessAddressShort: "כתובת",
      businessAddressFull: "כתובת מלאה (כולל דרכי הגעה מפורטות)"
    },
    contactDetails: {
      title: "פרטי יצירת קשר",
      phone1: "טלפון 1",
      phone2: "טלפון 2",
      email: 'דוא"ל',
      site: "קישור לאתר אינטרנט (במידה וקיים)",
      socialNetworksList: "רשתות חברתיות"
    },
    clinicInfo: {
      title: "פרטי המרפאה",
      text: "אודות המרפאה",
      service: "טיפולים המוצעים במרפאה",
      addService: "הוספת טיפול",
      serviceExist: "הטיפול כבר קיים ברשימה",
      workHours: "ימים ושעות פעילות המרפאה"
    },
    media: {
      title: "מדיה",
      businessLogo: "לוגו המרפאה",
      businessBackround: "תמונת רקע (התמונה תוצג כרקע ראשי באתר)",
      gallery: "גלריה",
      attachmentText: "העלה קובץ מצורף (עד 5 תמונות)",
      table: {
        columns: {
          createdAt: "תאריך",
          name: "שם הקובץ"
        }
      }
    },
    finalStep: {
      title: "אישור ויצירת האתר"
    }
  }
}
export default {
  head: {
    title: 'דוח מע"מ',
    meta: [
      { name: "description", content: 'דוח מע"מ' }
    ]
  },
  title: 'דוח מע"מ',
  alertText: 'דוח זה מאפשר חישוב מע"מ לתשלום עפ המחזור העסקי שלכם וההוצאות שיצרתם במערכת.',
  alertLink: 'לתשלום מע"מ באתר רשות המיסים',
  dateFrom: "מתאריך",
  dateTo: "עד תאריך",
  vatPayment: 'תשלום מע"מ',
  transactions: 'עסקאות',
  debitTransactions: 'עסקאות חייבות (ללא מע"מ)',
  exemptTransactions: 'עסקאות פטורות או בשיעור אפס',
  vatTransactions: 'המס על העסקאות',
  inputs: 'תשומות',
  knownExpensesInput: 'הוצאות מוכרות (ללא מע"מ)',
  taxInput: 'מס תשומות על ציוד ונכסים קבועים',
  otherTaxInput: 'מס תשומות אחרות',
  totalVatPaid: 'סה"כ מע"מ לתשלום',
  buttons: {
    report: "הפקת דוח"
  }
}

import type { EmailProviderType } from "@/models/enums";

export interface EmailSettingSchema {
  emailProviderType: EmailProviderType;

  imapEmail: string;
  imapPassword: string;
  imapUserName: string;
  imapServer: string;
  imapPort: string;
  imapUseSsl: boolean;

  smtpPassword: string;
  smtpUserName: string;
  smtpServer: string;
  smtpPort: string;
  smtpUseSsl: boolean;
}

export const emailSettingSchemaAllFields = `
  emailProviderType

  imapEmail
  imapPassword
  imapUserName
  imapServer
  imapPort
  imapUseSsl

  smtpPassword
  smtpUserName
  smtpServer
  smtpPort
  smtpUseSsl
`;
export default {
  title: "קבצים",
  createNew: "הוספה",
  folder: "תיקייה חדשה",
  file: "העלאת קבצים",
  linkText: "סריקת מסמך: {0}",
  cameraPhoto: "מצלמה",
  rootFolder: "ראשי",
  search: "חיפוש לפי שם...",
  uploadFiles: "העלאת קבצים",
  open: "פתיחת קובץ",
  rename: "שינוי שם",
  remove: "מחיקה",
  send: "שליחת קובץ",
  writeFolderName: "בבקשה לרשום שם תיקיה",
  errorFolderName: "שם תיקיה לא תקים",
  writeNewName: "בבקשה לרשום שם קובץ",
  errorNewName: "שם קובץ לא תקים",
  table: {
    columns: {
      name: "שם",
      dateModified: "תאריך יצירה",
      size: "גודל",
    }
  },
  camera: {
    title: "צילום תמונה",
  },
  popup: {
    notification: "ההודעה נשלחה בהצלחה",
    title: "שליחת קובץ למטופל",
    subject: "כותרת",
    message: "הודעה",
    validation: {
      subject: "כותרת הינה חובה.",
      message: "הודעה הינה חובה.",
    }
  }
}

import moment from "@/plugins/VueMomentPlugin";
import { isArray } from "@apollo/client/utilities";
import type { DateTimeString, TimeSpanString, TimeModel, MonthModel } from "@/models/interfaces";
import appConfig from "@/app.config";
import { i18n } from "@/locales/i18n";
import { toJewishDate, formatJewishDate, formatJewishDateInHebrew } from "jewish-date";

export class DateInput {
  public static get days() {
    return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].map((day, i) => ({
      code: day,
      get dd() { return moment().day(i).format("dd"); },
      get dddd() { return moment().day(i).format("dddd"); }
    }));
  }
  public static get hours() {
    return Array.from({ length: 24 }, (_, i) => i).map((x) => ({ label: `${x}:00`, value: x }));
  }

  public static getTodayDate(hours?: number, minutes?: number): Date {
    return moment().startOf("day").add(hours ?? 0, 'hours').add(minutes ?? 0, 'minutes').toDate();
  }
  public static getFutureDate(days: number, hours?: number, minutes?: number): Date {
    return moment().startOf("day").add(days, 'days').add(hours ?? 0, 'hours').add(minutes ?? 0, 'minutes').toDate();
  }

  public static getTimezoneOffsetHours() {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    return Math.round(new Date().getTimezoneOffset() / -60);
  }

  //#region DateTime
  public static dateTimeToDate(dateTime?: DateTimeString | null) {
    return dateTime ? moment(dateTime).toDate() : null;
  }

  public static dateToDateTimeString(date?: Date | null): DateTimeString | null {
    return date ? date.toISOString() : null;
  }

  public static dateToDateString(date?: Date | null | (Date | null)[], index?: number): DateTimeString | null {
    if (isArray(date)) date = date[index ?? 0];
    return date ? moment(date).format("YYYY-MM-DD") + "T00:00:00Z" : null;
  }

  public static dateToTimeSpanString(date: Date): TimeSpanString {
    return this.timeModelToTimeSpanString({ hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds() });
  }

  public static getMinutesBetweenDates(date1: Date, date2: Date) {
    // https://stackoverflow.com/a/34869030/22548940
    return Math.abs(moment(date2).diff(date1, 'minutes'));
  }

  public static getDurationBetweenDates(startTime: Date, endTime?: Date | null) {
    if (!endTime) return null;
    const duration = moment.duration(moment(endTime).diff(startTime));
    return duration.toISOString();
  }
  //#endregion

  //#region TimeSpan
  public static timeSpanToDate(timeSpan: TimeSpanString) {
    const duration = moment.duration(timeSpan);
    return moment.utc().startOf("day").add(duration).toDate();
  }
  public static timeSpanToLocalTimeString(timeSpan: TimeSpanString) {
    return moment.duration(timeSpan).add(this.getTimezoneOffsetHours(), "hours").format("HH:mm");
  }
  //#endregion

  //#region TimeModel
  public static dateTimeToTimeModel(dateTime: Date | TimeSpanString, skipSeconds?: boolean) {
    const date = moment(dateTime);
    const timeModel: TimeModel = {
      hours: date.hours(),
      minutes: date.minutes(),
      seconds: skipSeconds ? undefined : date.seconds()
    }
    return timeModel;
  }
  public static timeSpanToTimeModel(timeSpan: TimeSpanString, skipSeconds?: boolean) {
    const duration = moment.duration(timeSpan);
    const timeModel: TimeModel = {
      hours: duration.hours() + this.getTimezoneOffsetHours(),
      minutes: duration.minutes(),
      seconds: skipSeconds ? undefined : duration.seconds()
    }
    return timeModel;
  }
  public static timeModelToTimeSpanString(duration: TimeModel): TimeSpanString {
    // https://momentjs.com/docs/#/durations/creating
    let hours = (duration.hours - this.getTimezoneOffsetHours() % 24 + 24) % 24;
    return moment.duration({
      hours: hours, // convert to UTC TimeSpan
      minutes: duration.minutes,
      seconds: duration.seconds || 0,
    }).toISOString();
  }
  //#endregion

  //#region Month
  public static dateTimeToMonthModel(dateTime: DateTimeString) {
    return this.dateToMonthModel(new Date(dateTime));
  }
  public static dateToMonthModel(date: Date): MonthModel {
    return { month: date.getMonth(), year: date.getFullYear() };
  }

  public static monthModelToDateString(month: MonthModel): DateTimeString {
    return moment(month).format("YYYY-MM") + "-01T00:00:00Z";
  }
  public static monthModelToDate(month: MonthModel): Date {
    return moment(month).utc(true).toDate();
  }
  //#endregion

  //#region Format
  public static formatTimeSpan(timeSpan: TimeSpanString) {
    // https://momentjs.com/docs/#/durations/
    return moment.duration(timeSpan).format(appConfig.format.time);
  }

  public static formatTime(date: Date | DateTimeString) {
    return moment(date).format(appConfig.format.time);
  }

  public static formatDate(date: Date | DateTimeString) {
    return moment(date).format(appConfig.format.date);
  }

  public static formatDateTime(date: Date | DateTimeString) {
    return moment(date).format(appConfig.format.dateTime);
  }

  public static formatDateTimeWithDay(date: Date | DateTimeString) {
    return moment(date).format(appConfig.format.dateTimeWithDay);
  }

  public static formatDateWithWeekDay(date: Date | DateTimeString) {
    return moment(date).format(appConfig.format.dateWithWeekDay);
  }

  public static formatMonthWithYear(date: Date | DateTimeString) {
    return moment(date).format(appConfig.format.monthWithYear);
  }

  public static formatJewishDate(date: Date) {
    // https://github.com/Shmulik-Kravitz/jewish-date https://github.com/moment/moment/issues/1454
    const jewishDate = toJewishDate(date);
    if (i18n.global.locale.value === "he") {
      return formatJewishDateInHebrew(jewishDate);
    } else {
      return formatJewishDate(jewishDate);
    }
  }
  //#endregion
}

export default {
  head: {
    title: "Pricelist",
    meta: [
      { name: "description", content: "Pricelist" }
    ]
  },
  table: {
    columns: {
      serviceName: "Service"
    },
  },
  groups: "Pricelist groups",
  info: "Click on the price to change the price",
  updateByType: "Update prices",
  searchByName: "Service / Item name",
  update: {
    title: "Update prices",
    pricelistType: "Pricelist",
    percentChange: "Percent change",
    changePrices: "Update prices",
    allPricelists: "All pricelists",
    info: "To increase the price by 10%, choose \"Increase prices\" in the type of change and add 10 in \"Percent change\"",
    priceOperation: "Operation",
    priceOperationType: [
      "None",
      "Increase prices",
      "Decrease prices"
    ]
  }
}

export default {
  head: {
    title: "MedForm",
    meta: [
      { name: "description", content: "MedForm login" }
    ]
  },
  welcome: "Welcome Back!",
  signIn: "Sign in to continue",
  input: {
    userName: "Username",
    password: "Password",
    dbName: "Database"
  },
  dbSelect: "Select database",
  logIn: "Log In",
  forgot: "Forgot your password?",
  reset: "Reset",
  user_wrong_data: "Wrong user name or password",
  user_inactive: "Inactive user",
  user_password_expiry: "Your password has expired",
  license: {
    expired: "License expired, please contact support",
    notFound: "License not found, Please contact support."
  }
}

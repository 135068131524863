import type { DateTimeString } from "@/models/interfaces";
import type { TreatmentStageStatus, DiscountType, TreatmentPaidStatus } from "@/models/enums";

export interface TreatmentPlanSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;

  patientId: number;
  doctorId: number;
  status: TreatmentStageStatus;
  title: string;
  description: string | null;

  subTotal: number;
  discountType: DiscountType;
  discountValue: number;
  total: number;

  finished: number;
  paid: number;
  paidStatus: TreatmentPaidStatus;
  doctorTreatmentPaymentId: number | null;

  patientName: string;
  doctorName: string;
  due: number;
}

export const treatmentPlanSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "patientId",
  "doctorId",
  "status",
  "title",
  "description",
  "subTotal",
  "discountType",
  "discountValue",
  "total",
  "finished",
  "paid",
  "paidStatus",
  "doctorTreatmentPaymentId",
  "patientName",
  "doctorName",
  "due",
];
